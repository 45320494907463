import style from './reviews-section.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
// carousel styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ICustomerReviewsSectionComment } from '@components/main-page/main-page.state';

interface IReviewsSectionProps{
    title: string;
    comments: ICustomerReviewsSectionComment[];
}

const ReviewsSectionComponent = ({title, comments}: IReviewsSectionProps) => {
    return (
        <section className={`${template.section} ${template.section_gray} ${template.section_reviews}`}>
            <div className={`${template.container} ${template.container_large} ${style.reviews}`}>
                <h2 className={`${style.reviews__title}`}>
                    {title}
                </h2>
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    mousewheel={true}
                    className={`${style.reviews__carousel}`}>
                    {
                        comments.map((review: ICustomerReviewsSectionComment, index: number) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={`${style.reviews__carousel__item}`}>
                                        <div className={`${style.reviews__carousel__item__comment}`}>
                                            {review.comment}
                                        </div>
                                        <div className={`${style.reviews__carousel__item__specialistName}`}>
                                            {review.specialistName}
                                        </div>
                                        <div className={`${style.reviews__carousel__item__company}`}>
                                            {`${review.companyName}, ${review.specialist}`}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default ReviewsSectionComponent;
